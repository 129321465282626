import React from "react";

const LandingSectionComponent = ({ imageUrl, children, height = null, opacity = null }) => {
  const imageStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="pt-0 relative">
      <div
        style={imageStyle}
        className={`bg-cover ${
          height !== null ? height : "h-[80vh]"
        } bg-center`}
      ></div>
      <div
        className={`bg-cover ${
          height !== null ? height : "h-[80vh]"
        } w-full bg-center bg-black ${
          opacity !== null ? opacity : "opacity-40"
        } absolute top-0 left-0 right-0 bottom-0`}
      ></div>
      <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full text-white absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6">
        {children}
      </div>
    </div>
  );
};

export default LandingSectionComponent;
