import React, { useEffect } from "react";
import LandingSectionComponent from "../../../components/LandingSectionComponent";
import landing_image_url from "../../../assets/courses/advanced-diploma-landing.png";
import Qualification from "../component/Qualification";
import {
  advanced_diploma_assignment,
  advanced_diploma_course_fee,
  advanced_diploma_course_req,
  advanced_diploma_delivery,
  advanced_diploma_qualification,
  advanced_diploma_units,
} from "../../../data/courseData";
import ModeOfDelivery from "../component/ModeOfDelivery";
import CourseRequirment from "../component/CourseRequirment";
import AssignmentSystem from "../component/AssignmentSystem";
import CourseFee from "../component/CourseFee";
import AdvancedFollowingUnit from "./AdvancedFollowingUnit";

const AdvancedDiplomaPageLayout = () => {
  useEffect(() => {
    document.title =
      "FNS60620 - Advanced Diploma of Banking Services Management | Reeves College";
    return () => {
      document.title = "Home | Reeves College";
    };
  }, []);
  return (
    <>
      <LandingSectionComponent
        imageUrl={landing_image_url}
        height="h-[40vh] md:h-[60vh]"
      >
        <p className="text-2xl md:text-4xl xl:w-2/3 text-white font-bold font-poppins text-center">
          {"FNS60620 - Advanced Diploma of Banking Services Management"}
        </p>
      </LandingSectionComponent>
      <Qualification data={advanced_diploma_qualification} />
      <ModeOfDelivery data={advanced_diploma_delivery} />
      <CourseRequirment data={advanced_diploma_course_req} />
      <AssignmentSystem data={advanced_diploma_assignment} />
      <AdvancedFollowingUnit data={advanced_diploma_units} />
      <CourseFee data={advanced_diploma_course_fee} />
    </>
  );
};

export default AdvancedDiplomaPageLayout;
