import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Image } from "../../components/Image";
import NavBarSubMenu from "./NavBarSubMenu";
import { course_sub_menu } from "./NavBarSubMenuData";
import { GoMail } from "react-icons/go";
import { GrPhone } from "react-icons/gr";
import { useNavBarOpen } from "./ContextAPI/NavBarContext";
import NavigationMobile from "./NavigationMobile";

const NavigationBar = () => {
  const { open, setOpen } = useNavBarOpen();

  //this is for logo animating
  const [isLogoAnimating, setIsLogoAnimating] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLogoAnimating(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleEmailOpen = () => {
    window.open("mailto:info@reevescollege.edu.au", "_self");
  };

  const handlePhoneOpen = () => {
    window.open("tel:", "_self");
  };

  return (
    <>
      <div className="hidden bg-marron md:flex py-2 gap-5 justify-end pr-80">
        <div className="flex items-center gap-3">
          <div className="p-1.5 bg-white w-fit rounded-full">
            <GoMail className="h-4 w-4" color="rgb(170, 34, 51)" />
          </div>
          <p className="text-white cursor-pointer" onClick={handleEmailOpen}>
            info@reevescollege.edu.au
          </p>
        </div>
        <div className="flex items-center gap-3">
          <div className="p-1.5 bg-white w-fit rounded-full">
            <GrPhone className="h-4 w-4" color="rgb(170, 34, 51)" />
          </div>
          <p className="text-white cursor-pointer" onClick={handlePhoneOpen}>
            02&nbsp;7226&nbsp;8887
          </p>
        </div>
      </div>
      {/* navbar code here */}
      <div className="relative z-40 top-0 w-full bg-white px-[5vw] xl:h-[150px] h-[100px] shadow-custom flex items-start justify-between xl:justify-center">
        <div
          className={`w-fit bg-white shadow-custom px-5 py-4 rounded-b-3xl mr-16 transition-all ease-in-out duration-1000 ${
            isLogoAnimating ? "translate-y-[0%] opacity-100" : "opacity-0 -translate-y-[25%]"
          }`}
        >
          <NavLink to="/">
            <Image
              className="w-[80px] xl:w-[130px]"
              src="../../assets/logos/logo.png"
            />
          </NavLink>
        </div>

        {/* For mobile or small screen ham-burger*/}
        <button
          className="block h-full navBP:hidden"
          onClick={() => setOpen(true)}
        >
          <RxHamburgerMenu className="w-10 h-7 text-marron" />
        </button>
        {/* For mobile or small screen ham-burger*/}

        {/* For 1024px to large screen */}
        <div className="hidden navBP:flex navBP:items-center navBP:justify-center navBP:cursor-pointer h-full">
          <NavBarSubMenu name="Home" route="/" />
          <NavBarSubMenu name="About Us" route="/about-us" />
          <NavBarSubMenu
            name="Courses"
            route="/courses"
            subMenu={true}
            subMenuData={course_sub_menu}
          />
          <NavBarSubMenu
            name="University Pathway"
            route="/university-pathway"
          />
          <NavBarSubMenu name="Agents" route="/agents" />
          <NavBarSubMenu name="All Forms" route="/all-forms" />
          <NavBarSubMenu name="Contact Us" route="/contact-us" />
        </div>
        {/* For 1024px to large screen */}
      </div>
      {/* For mobile or small screen modal*/}
      <div
        className={`${
          open ? "translate-x-0" : "translate-x-full"
        } fixed z-50 top-0 left-0 bottom-0 right-0 transform transition-transform duration-500 ease-in-out`}
      >
        <NavigationMobile />
      </div>
      {/* For mobile or small screen modal*/}
    </>
  );
};

export default NavigationBar;
