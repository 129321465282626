import React from "react";
import { Image } from "../../../components/Image";
import { about_section } from "../../../data/aboutData";

const AboutSection = () => {
  return (
    <div className="px-[5vw] lg:px-[10vw] xl:px-[15vw] py-10 flex flex-col justify-center w-full">
      <div className="flex flex-col gap-5 lg:gap-10 lg:flex-row">
        <div className="space-y-5 lg:w-1/2">
          <p className="font-poppins font-bold text-3xl lg:text-4xl text-marron">
            About Us
          </p>
          <p className="font-futura text-justify">
            {about_section.description}
          </p>
        </div>
        <div className="relative p-1 shadow-custom w-fit h-fit lg:w-1/2 lg:h-auto">
          <Image src={about_section.image_url} className="w-full h-full" />
          <div className="absolute inset-0 bg-white opacity-10 hover:opacity-0"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
