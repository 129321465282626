import React from 'react'
import LandingSectionComponent from '../../../components/LandingSectionComponent'
import image_url from "../../../assets/about/landing-section-image.png"

const LandingSection = () => {
  return (
    <div>
        <LandingSectionComponent imageUrl={image_url} height="h-[40vh] md:h-[60vh]">
            <p className="text-2xl md:text-4xl text-white font-bold font-poppins uppercase">About Us</p>
        </LandingSectionComponent>
    </div>
  )
}

export default LandingSection