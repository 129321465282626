import React from "react";
import { Image } from "../../../components/Image";
import { qualification_section } from "../../../data/homeData";
import { Button } from "../../../components/Button";
import { NavLink } from "react-router-dom";

const QualificationSection = () => {
  return (
    <div className="flex flex-col justify-center w-full lg:p-8">
      <div className="flex flex-col lg:gap-2 lg:flex-row-reverse">
        <div className="py-5 lg:w-1/2 px-6 bg-marron flex flex-col gap-4 justify-center items-center">
          <p className="font-poppins font-bold text-3xl lg:text-4xl text-white">
            Qualification
          </p>
          <p className="font-futura text-justify text-white pb-2 w-3/4">
            {qualification_section.description}
          </p>
          <div>
            <NavLink to="/about-us">
              <Button>Learn More</Button>
            </NavLink>
          </div>
        </div>
        <div className="relative bg-white py-6 lg:py-0 lg:w-1/2">
          <Image
            src={qualification_section.image_url}
            className="w-full max-h-[500px] h-full"
          />
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
      </div>
    </div>
  );
};

export default QualificationSection;
