import React from "react";

const GraduateFollowingUnit = ({ data }) => {
  return (
    <div>
      <h1 className="px-[5vw] py-20 font-poppins font-bold text-white bg-marron text-center text-xl">
        {data.title}
      </h1>
      <div className="pb-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] 2xl:px-[22vw] space-y-5 pt-5">
        <div className="space-y-1">
          <h1 className="font-poppins font-bold text-marron text-xl">
            {data.core_unit.name}
          </h1>
          {data.core_unit.para.map((item, index) => (
            <div key={index}>
              <p className="font-futura text-base">
                <span>{item.slice(0, 9)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                {item.slice(9)}
              </p>
            </div>
          ))}
        </div>
        <div className="space-y-1">
          <h1 className="font-poppins font-bold text-marron text-xl">
            {data.elective_unit.name}
          </h1>
          {data.elective_unit.para.map((item, index) => (
            <div key={index}>
              <p className="font-futura text-base">
                <span>{item.slice(0, 9)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                {item.slice(9)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GraduateFollowingUnit;
