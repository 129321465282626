import React, { createContext, useContext, useState } from 'react';

const NavBarContext = createContext();

export const NavBarProvider = ({children}) =>{
    const [open, setOpen] = useState(false);

    return(
        <NavBarContext.Provider value={{open, setOpen}}>
            {children}
        </NavBarContext.Provider>
    )
}

export const useNavBarOpen = () =>{
    return useContext(NavBarContext);
}