import React, {useEffect} from "react";
import LandingSectionComponent from "../../components/LandingSectionComponent";
import { course_section_data } from "../../data/courseData";
import { Image } from "../../components/Image";
import { NavLink } from "react-router-dom";

const CoursesPageLayout = () => {
  useEffect(() => {
    document.title = 'Courses | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);

  return (
    <div>
      <LandingSectionComponent
        imageUrl={course_section_data.image_url}
        height="h-[40vh] md:h-[60vh]"
      >
        <p className="text-2xl md:text-4xl text-white font-bold font-poppins uppercase">
          Courses
        </p>
      </LandingSectionComponent>
      <div className="py-[5vh] md:py-[8vh] px-[5vw] lg:px-[10vw] xl:px-[15vw] flex flex-col lg:flex-row">
        <div className="space-y-5 lg:w-3/4">
          <h1 className="font-poppins font-bold text-marron text-xl lg:text-3xl">
            {course_section_data.title}
          </h1>
          <p className="font-futura text-base">
            {course_section_data.paragraph_one}
          </p>
          <p className="font-futura text-base">
            {course_section_data.paragraph_two}
          </p>
          <div className="space-y-3">
            <p className="font-futura text-base">
              {course_section_data.paragraph_three}
            </p>
            <div className="flex flex-col items-start ml-5">
              <li className="font-futura text-base">
                {course_section_data.paragraph_bullet[0]}
              </li>
              <li className="font-futura text-base">
                {course_section_data.paragraph_bullet[1]}
              </li>
            </div>
          </div>
        </div>
        <div className="py-10 lg:py-0 flex justify-center items-center lg:w-1/3">
          <NavLink to='/'>
            <Image
              src={course_section_data.image_logo_url}
              className="w-[200px] lg:max-w-[300px]"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default CoursesPageLayout;
