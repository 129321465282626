import React from "react";
import { our_principles_section } from "../../../data/aboutData";
import { TbArrowRightTail } from "react-icons/tb";
import { Image } from "../../../components/Image";

const OurPrincipleSection = () => {
  return (
    <div className="px-[5vw] lg:px-[10vw] xl:px-[15vw] py-10">
      <h1 className="py-5 text-2xl xl:text-4xl text-marron font-bold font-poppins">
        {our_principles_section.title}
      </h1>
      <div className="flex flex-col lg:flex-row lg:gap-2">
        <div className="flex flex-col gap-2 lg:w-1/2">
          {our_principles_section.principle.map((item) => (
            <div key={item.id}>
              <div className="flex gap-1 items-center">
                <TbArrowRightTail />
                <p className="font-bold font-futura text-[15px]">{item.name}</p>
              </div>
              <p className="font-futura text-[14px]">{item.description}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-center lg:w-1/2">
          <Image
            src={our_principles_section.image_url}
            className="w-full h-full max-w-[500px] max-h-[500px]"
          />
        </div>
      </div>
    </div>
  );
};

export default OurPrincipleSection;
