import React from "react";
import image_url from "../../../assets/university/landing-section.png";

const LandingSection = () => {
  const imageStyle = {
    backgroundImage: `url(${image_url})`,
  };

  return (
    <div className="pt-0 relative flex justify-center items-center w-full">
      <div style={imageStyle} className="bg-cover h-[40vh] sm:h-[50vh] md:w-3/4 xl:w-1/2 w-full bg-center"></div>
      {/* <div
        className="bg-cover h-[40vh] sm:h-[50vh] md:w-3/4 xl:w-1/2 w-full bg-center bg-black opacity-10 absolute top-0 left-0 right-0 bottom-0"
      ></div> */}
      {/* <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full text-white absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6"></div> */}
    </div>
  );
};

export default LandingSection;
