import React from "react";

const AdvancedFollowingUnit = ({ data }) => {
  return (
    <div>
      <h1 className="px-[5vw] py-20 font-poppins font-bold text-white bg-marron text-center text-xl">
        {data.title}
      </h1>
      <div className="py-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] 2xl:px-[22vw] space-y-5">
        <div className="space-y-10">
          {data.require_skills_one.map((item) => (
            <div key={item.id} className="space-y-1">
              <h1 className="font-futura font-bold">{item.name}</h1>
              <p className="font-futura text-base">{item.description}</p>
            </div>
          ))}
        </div>
        <p className="font-futura text-base pt-5">{data.para}</p>
        <div className="space-y-10">
          {data.require_skills_two.map((item) => (
            <div key={item.id} className="space-y-1">
              <h1 className="font-futura font-bold">{item.name}</h1>
              <p className="font-futura text-base">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvancedFollowingUnit;
