import React from 'react'
import { GoMail } from "react-icons/go";
import { GrPhone } from "react-icons/gr";
import { TfiLocationPin } from "react-icons/tfi";

const LinkSection = () => {
    const handleEmailOpen = () => {
        window.open("mailto:info@reevescollege.edu.au", "_self");
      };
    
      const handlePhoneOpen = () => {
        window.open("tel:", "_self");
      };
  return (
    <div className="flex justify-center pt-5">
        <div className="flex flex-col gap-3 py-10 px-[5vw] md:w-1/2 xl:w-[800px] md:justify-center items-start">
          <div className="flex flex-col justify-center gap-3 md:flex-row md:justify-between w-full">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-marron w-fit rounded-full">
                <GoMail className="h-6 w-6" color="white" />
              </div>
              <p
                className="text-marron cursor-pointer font-futura text-[18px]"
                onClick={handleEmailOpen}
              >
                info@reevescollege.edu.au
              </p>
            </div>
            <div className="flex items-center gap-3">
              <div className="p-2 bg-marron w-fit rounded-full">
                <GrPhone className="h-6 w-6" color="white" />
              </div>
              <p
                className="text-marron cursor-pointer font-futura text-[18px]"
                onClick={handlePhoneOpen}
              >
                02&nbsp;7226&nbsp;8887
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="p-2 bg-marron w-fit rounded-full">
              <TfiLocationPin className="h-6 w-6" color="white" />
            </div>
            <p className="text-marron cursor-pointer font-futura text-[18px]">
              Level&nbsp;1 | 29&nbsp;TheCrescent Homebush NSW 2140
            </p>
          </div>
        </div>
      </div>
  )
}

export default LinkSection