import React, {useEffect} from "react";
import LandingSectionComponent from "../../components/LandingSectionComponent";
import image_url from "../../assets/contact/contact-landing.png";
import LinkSection from "./section/LinkSection";
import ContactFormSection from "./section/ContactFormSection";

const ContactPageLayout = () => {
  useEffect(() => {
    document.title = 'Contact Us | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);
  return (
    <>
      <LandingSectionComponent
        imageUrl={image_url}
        height="h-[30vh] md:h-[50vh]"
      >
        <p className="text-2xl md:text-4xl text-white font-bold font-poppins uppercase">
          Contact Us
        </p>
      </LandingSectionComponent>
      <LinkSection/>
      <ContactFormSection/>
    </>
  );
};

export default ContactPageLayout;
