import React, {useEffect} from "react";
import LandingSectionComponent from "../../../components/LandingSectionComponent";
import landing_image_url from "../../../assets/courses/graduate-diploma-landing.png";
import Qualification from "../component/Qualification";
import {
  graduate_diploma_assignment,
  graduate_diploma_course_fee,
  graduate_diploma_course_req,
  graduate_diploma_delivery,
  graduate_diploma_qualification,
  graduate_diploma_units,
} from "../../../data/courseData";
import ModeOfDelivery from "../component/ModeOfDelivery";
import CourseRequirment from "../component/CourseRequirment";
import AssignmentSystem from "../component/AssignmentSystem";
import GraduateFollowingUnit from "./GraduateFollowingUnit";
import CourseFee from "../component/CourseFee";

const GraduateDiplomaPageLayout = () => {
  useEffect(() => {
    document.title = 'BSB80120 – Graduate Diploma of Management (Learning) | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);

  return (
    <>
      <LandingSectionComponent
        imageUrl={landing_image_url}
        height="h-[40vh] md:h-[60vh]"
      >
        <p className="text-2xl md:text-4xl xl:w-2/3 text-white font-bold font-poppins text-center">
          {"BSB80120 – Graduate Diploma of Management (Learning)"}
        </p>
      </LandingSectionComponent>
      <Qualification data={graduate_diploma_qualification} />
      <ModeOfDelivery data={graduate_diploma_delivery} />
      <CourseRequirment data={graduate_diploma_course_req} />
      <AssignmentSystem data={graduate_diploma_assignment} />
      <GraduateFollowingUnit data={graduate_diploma_units} />
      <CourseFee data={graduate_diploma_course_fee} />
    </>
  );
};

export default GraduateDiplomaPageLayout;
