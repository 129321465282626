import React from "react";
import { about_card_section } from "../../../data/aboutData";
import { CiLocationOn } from "react-icons/ci";
import { CiBadgeDollar } from "react-icons/ci";
import { MdOutlineGppGood } from "react-icons/md";

const AboutCardSection = () => {
  return (
    <div className="flex justify-center gap-5 px-[5vw] lg:px-[10vw] xl:px-[15vw] py-10 flex-row flex-wrap bg-ash">
        {about_card_section.map((item) => (
          <div
            key={item.id}
            className="flex flex-col justify-start items-center shadow-custom px-5 py-8 flex-1 min-w-[320px] lg:max-w-[380px] bg-white rounded"
          >
            {item.id === 1 && (
              <div>
                <MdOutlineGppGood className="w-[80px] h-[80px]" />
              </div>
            )}
            {item.id === 2 && (
              <div>
                <CiBadgeDollar className="w-[80px] h-[80px]" />
              </div>
            )}
            {item.id === 3 && (
              <div>
                <CiLocationOn className="w-[80px] h-[80px]" />
              </div>
            )}
            <div className="text-center space-y-2">
              <h1 className="font-poppins text-marron font-bold">{item.title}</h1>
              <p className="font-futura">{item.description}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AboutCardSection;
