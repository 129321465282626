import React from "react";

const AssignmentSystem = ({ data }) => {
  return (
    <div className="flex flex-col gap-5 py-8 lg:py-16 px-[5vw] lg:px-[10vw] xl:px-[15vw] 2xl:px-[22vw]">
      <h1 className="font-poppins font-bold text-marron text-xl lg:text-3xl">
        {data.title}
      </h1>
      <div className="space-y-5">
        <li className="font-futura text-base ml-3">{data.bullet_one}</li>
        <li className="font-futura text-base ml-3">{data.bullet_two}</li>
      </div>
      <p className="font-futura text-base">{data.para_one}</p>
      <p className="font-futura text-base">{data.para_two}</p>
      <div className="space-y-1">
        <p className="font-futura text-base">{data.name}</p>
        <p className="font-futura text-base">{data.para_three}</p>
        <p className="font-futura text-base">{data.para_four}</p>
      </div>
    </div>
  );
};

export default AssignmentSystem;
