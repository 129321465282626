import React from "react";

const Qualification = ({ data }) => {
  return (
    <div className="px-[5vw] py-8 lg:py-16 flex justify-center items-center">
      <div className="shadow-custom space-y-5 text-center px-[5vw] py-[5vh] lg:py-[10vh] max-w-[800px]">
        <h1 className="font-poppins font-bold text-marron text-xl lg:text-3xl">{data.title}</h1>
        <p className="font-futura text-base">{data.description}</p>
      </div>
    </div>
  );
};

export default Qualification;
