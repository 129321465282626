import React from "react";
import { Image } from "../../../components/Image";
import { why_reeves_section } from "../../../data/aboutData";

const WhyReevesCollegeSection = () => {
  return (
    <div className="flex flex-col justify-center w-full lg:p-8">
      <div className="flex flex-col lg:gap-2 lg:flex-row-reverse">
        <div className="py-5 lg:w-1/2 px-[5vw] bg-marron flex flex-col gap-4 justify-center items-start">
          <p className="font-poppins font-bold text-2xl lg:text-3xl text-white">
            {why_reeves_section.title}
          </p>
          <div className="text-white pb-2 space-y-2">
            <div className="flex gap-2">
              <span>1.&nbsp;</span>
              <div>
                <p className="font-futura ">
                  {why_reeves_section.description.one}
                </p>
                <div className="flex gap-2 ml-2">
                  <span>1.&nbsp;</span>
                  <p className="font-futura ">
                    {why_reeves_section.description.sub_one}
                  </p>
                </div>
                <div className="flex gap-2 ml-2">
                  <span>2.&nbsp;</span>
                  <p className="font-futura ">
                    {why_reeves_section.description.sub_two}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <span>2.&nbsp;</span>
              <p className="font-futura">
                {why_reeves_section.description.two}
              </p>
            </div>
          </div>
        </div>
        <div className="relative bg-white py-6 lg:py-0 lg:w-1/2">
          <Image
            src={why_reeves_section.image_url}
            className="w-full max-h-[500px] h-full"
          />
          <div className="absolute inset-0 bg-black opacity-10 w-full h-full"></div>
        </div>
      </div>
    </div>
  );
};

export default WhyReevesCollegeSection;
