import * as Yup from "yup";

export const homeSectionValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(
      /^(\d{10})$/,
      "Invalid phone number"
    ),
  message: Yup.string().required("Message is required*"),
});

export const contactSectionValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(
      /^(\d{10})$/,
      "Invalid phone number"
    ),
  message: Yup.string().required("Message is required*"),
});

export const allFormSectionValidationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required*"),
  email: Yup.string().email("Invalid email").required("Email is required*"),
  phone: Yup.string()
    .required("Phone is required*")
    .matches(
      /^(\d{10})$/,
      "Invalid phone number"
    ),
  message: Yup.string().required("Message is required*"),
  formName: Yup.string().required("Choose a form is required*"),
  recaptcha: Yup.string().required('Please verify that you are not a robot.'),
});