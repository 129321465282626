export const about_section = {
  description:
    "Reeves College is a vocational educational institute aiming to provide an extraordinary and progressive quality education for our students. Located at Burwood, Sydney, we came into being as a passionate endeavour of a group of professionals and educational entrepreneurs collectively with long time experience. As we all know that the world is moving faster than ever with the advancement of technology, it has its effect in the job market too. Just as the jobs are variegated and demanding multi-skill from fresh graduates, it is paramount for students to equip themselves with the growing needs of time. We design, alter, recreate, update our courses keeping this in mind. Our emphasis is quality with a unique blend of conceptual and practical learning skills.",
  image_url: require("../assets/about/about-image.png"),
};

export const why_reeves_section = {
  title: "WHY REEVES COLLEGE?",
  description: {
    one: "A curriculum meeting the ever-changing demands of the industry, a student focused pedagogy, real life like training fulfilling Australian Training Package requirements.",
    sub_one:
      "A central location in the heart of Sydney closer to all amenities.",
    sub_two: "Flexibility to attend classes before or after work.",
    two: "Courses delivered by industry trained professionals who can offer guidance if you need to undertake additional studies to suit your needs.",
  },
  image_url: require("../assets/about/why-reeves-college.png"),
};

export const our_principles_section = {
  title: "OUR PRINCIPLES AND DEDICATION",
  principle: [
    {
      id: 1,
      name: "Academic excellence:",
      description:
        "Our commitment is academic excellence and we guarantee academic freedom for all students and high ethical standard in our services.",
    },
    {
      id: 2,
      name: "Privacy, rights and freedom:",
      description:
        "Individual rights, freedom of speech, privacy are some the basic principles of Australian Society and RC strictly uphold and adhere to these values in all it’s practices. This allows students to have a trans-formative learning experience so they can excel in their professional career.",
    },
    {
      id: 3,
      name: "Mutual respect and responsibility:",
      description:
        "A clear and succinct communication is pivotal to reach the goal of a mutually agreed outcome. RC aims to achieve that in all its activities, exchange of words, sharing ideas among parties comprising of teachers, faculties, students, management and the stakeholders in an honest and respectful way so that no one feels discriminated and everyone feels that their voices are heard. This strengthens fairness, clarity and transparency. RC makes sure that everyone owns up to their respective responsibility in that area.",
    },
    {
      id: 4,
      name: "Continuous learning:",
      description:
        "RC acknowledges it’s only natural to make errors. Errors and mistakes are observed as a learning tool by the College. RC encourages taking self-corrective measures with the expectation that they are not repetitive. This policy is all-inclusive and not limited to college staffs, faculties and students.",
    },
    {
      id: 5,
      name: "Collaboration and openness:",
      description:
        "RC encourage open discussion and constructive debate in order to achieve exciting academic and professional outcomes.",
    },
  ],
  image_url: require("../assets/about/our-principles-image.png"),
};

export const about_card_section = [
  {
    id: 1,
    title: "EXCELLENT TEACHING QUALITY",
    description:
      "Courses at RC are well-structured, delivered by experienced teachers and professionals. Trainers help students develop their skills to broaden their knowledge.",
  },
  {
    id: 2,
    title: "AFFORDABLE COURSE FEES",
    description:
      "Courses are offered at an affordable cost. Our rates are highly competitive in the industry.",
  },
  {
    id: 3,
    title: "CAMPUS LOCATION",
    description: "14 Railway Parade, Burwood",
  },
];
