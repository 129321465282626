import React from "react";
import { Formik, Form } from "formik";
import { ButtonSubmit } from "../../../components/Button";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import { homeSectionValidationSchema } from "../../../utils/formikFormValidation";
import { Image } from "../../../components/Image";
import { usePostApi } from "../../../api/usePostApiHook";
import { CONTACT_DETAILS_API } from "../../../api/apiUrl";

const ContactSection = () => {
  //api call custom hooks
  const { isLoading, makePostRequest } = usePostApi();

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    //api call
    try {
      await makePostRequest(CONTACT_DETAILS_API, values);
      setSubmitting(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <p className="text-4xl uppercase text-white font-bold font-poppins bg-marron text-center py-10">
        Contact Us
      </p>
      <div className="py-10 flex flex-col lg:flex-row lg:gap-2 lg:items-center lg:justify-center">
        <div className="flex justify-center items-center">
          <Image
            src="../../../assets/home/form-image.png"
            className="object-cover max-w-[350px] md:max-w-[450px] max-h-[350px] md:max-h-[450px]"
          />
        </div>
        <div className="m-5 flex justify-center">
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              phone: "",
              email: "",
              message: "",
              recaptcha: "",
            }}
            validationSchema={homeSectionValidationSchema}
            va
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col gap-3 p-5 sm:px-16 sm:py-16 w-full md:max-w-[600px] justify-start items-start rounded shadow-custom">
                <div className="flex flex-col mb:flex-row gap-3 w-full">
                  <InputField
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <InputField
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                </div>
                <InputField type="text" name="phone" placeholder="Phone*" />
                <InputField type="text" name="email" placeholder="Email*" />
                <TextArea name="message" placeholder="Message*" />
                {/* <div className="mt-2">
                <ReCAPTCHA
                  sitekey={clientSiteKey}
                  onChange={(value) => setFieldValue("recaptcha", value)}
                  size="normal"
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="text-red-900 text-[12px]"
                />
              </div> */}
                <div className="flex justify-end items-end w-full">
                  <ButtonSubmit
                    type="submit"
                    name="Submit"
                    disabled={isSubmitting}
                    loader={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
