import React from "react";
import { useField } from "formik";

const NewInputField = (props) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 items-start justify-start font-poppins">
        <label className="text-black font-poppins text-[14px]">
          {props.label}
        </label>
        <input
          {...field}
          {...props}
          className={`focus:outline-marron focus:bg-white text-black w-full ml-[-1.5px] px-2.5 py-2 text-[14px] border-[2px] border-ash rounded`}
        />
      </div>
      {meta.error && meta.touched && (
        <div className="text-[12px] text-red-900 mt-1">
          {meta.error}
          <sup>*</sup>
        </div>
      )}
    </div>
  );
};

export default NewInputField;
