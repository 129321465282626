import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { Accordion } from "@material-tailwind/react";
import NavBarMobileSubMenu from "./NavBarMobileSubMenu";
import { useNavBarOpen } from "./ContextAPI/NavBarContext";
import { course_sub_menu } from "./NavBarSubMenuData";

const NavigationMobile = () => {
  const { setOpen } = useNavBarOpen();
  const [link, setLink] = useState("");

  const handleClick = (value) => {
    setLink(link === value ? "" : value);
  };

  const activeLink = ({ isActive }) => {
    return {
      color: isActive ? "rgb(170, 34, 51)" : "black",
    };
  };

  return (
    <>
      <div className="w-screen h-screen bg-white relative z-0"></div>
      <div className="absolute z-40 top-0 w-screen h-screen flex flex-col p-6">
        <div className="flex justify-end">
          <p className="p-3 bg-ash-200 rounded-full" onClick={() => setOpen(false)}>
            <AiOutlineClose className="text-marron text-[25px]"/>
          </p>
        </div>
        <div className="flex flex-col justify-center items-center gap-6 h-full text-black overflow-y-auto">
          <div>
            <NavLink
              to="/"
              className="text-[17px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className="text-[16px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              About Us
            </NavLink>
            <Accordion open={link === "/courses"}>
              <NavBarMobileSubMenu
                name="Courses"
                subMenu={course_sub_menu}
                routeTo="/courses"
                onClick={handleClick}
              />
            </Accordion>
            <NavLink
              to="/university-pathway"
              className="text-[16px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              University Pathway
            </NavLink>
            <NavLink
              to="/agents"
              className="text-[16px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              Agents
            </NavLink>
            <NavLink
              to="/all-forms"
              className="text-[16px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              All Forms
            </NavLink>
            <NavLink
              to="/contact-us"
              className="text-[16px] py-3 block w-full font-poppins font-bold"
              style={activeLink}
              onClick={() => setOpen(false)}
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationMobile;
