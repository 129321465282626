import React from 'react'
import {Image} from "../../../components/Image"

const ModeOfDelivery = ({data}) => {
  return (
    <div className='pb-10 lg:py-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] flex flex-col gap-5 lg:gap-12 lg:justify-center lg:items-center lg:flex-row'>
        <div className='lg:w-1/2'>
            <Image src={data.image_url} className="max-h-[350px] w-full"/>
        </div>
        <div className='space-y-4 lg:w-1/2'>
            <h1 className="font-poppins font-bold text-marron text-xl lg:text-3xl">{data.title}</h1>
            <p className="font-futura text-base">{data.description}</p>
            <div className=' space-y-1'>
                <h1 className='font-futura font-bold'>{data.name}</h1>
                <p className="font-futura text-base">{data.location}</p>
            </div>
            <div className=' space-y-1'>
                <h1 className='font-futura font-bold'>{data.course_duration_title}</h1>
                <p className="font-futura text-base">{data.course_duration}</p>
            </div>
        </div>
    </div>
  )
}

export default ModeOfDelivery;