import React, { useState } from "react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Image } from "../components/Image";
import logo_url from "../assets/logos/drag-and-drop-logo.png";

const FileUploader = ({ files, setFiles }) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...droppedFiles]);
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="space-y-1">
      <label className="pl-3 font-poppins">
        Upload Your Supporting Documents*
      </label>
      <div
        className={`relative h-[120px] flex justify-center items-center border-[1.5px] border-dashed border-gray-400 p-4 ${
          dragging ? "bg-gray-200" : ""
        }`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex items-center justify-center">
          <p className="font-poppins">Drag and drop files here</p>
          <Image src={logo_url} className="max-w-[80px] max-h-[80px]" />
        </div>
        <input
          type="file"
          multiple
          onChange={handleFileInputChange}
          className="opacity-0 absolute w-full h-full"
        />
      </div>
      <div>
        {files.map((file, index) => (
          <div key={index} className="flex items-center justify-start gap-2">
            <button type="button" onClick={() => handleDeleteFile(index)}>
              <RiDeleteBin2Line className="w-4 h-4" color="red" />
            </button>
            <span>{file.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploader;
