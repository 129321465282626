import React from "react";
import { Image } from "../../../components/Image";

const CourseRequirment = ({ data }) => {
  return (
    <div className="bg-marron py-8 lg:px-8">
      <div className="bg-white py-5 px-[5vw] lg:px-[7vw] xl:px-[12vw] flex flex-col gap-10 lg:flex-row lg:pr-0 lg:pt-0 lg:pb-0 xl:pr-0 xl:pt-0 xl:pb-0">
        <div className="space-y-5 w-full lg:w-2/3 lg:py-10">
          <h1 className="font-poppins font-bold text-marron text-xl lg:text-3xl">
            {data.title}
          </h1>
          <p className="font-futura text-base">{data.description}</p>
          <div className="space-y-1.5">
            <div>
              <p className="font-futura text-base font-bold">
                {data.academic_req.name}
              </p>
              <li className="font-futura text-base ml-3">
                {data.academic_req.rule}
              </li>
            </div>
            <div>
              <p className="font-futura text-base font-bold">
                {data.english_req.name}
              </p>
              {data.english_req.rule.map((item, index) => (
                <div key={index}>
                  <li className="font-futura text-base ml-3">{item}</li>
                </div>
              ))}
            </div>
            <div className="hidden py-5 sm:flex justify-center items-center">
              <Image
                src={data.logo_url}
                className="max-h-[150px] max-w-[150px]"
              />
            </div>
          </div>
          <p className="font-futura text-base">{data.para_one}</p>
          <div className="space-y-5">
            <p className="font-futura text-base">{data.para_two}</p>
            <div className="space-y-5">
              <li className="font-futura text-base ml-5">{data.bullet_one}</li>
              <li className="font-futura text-base ml-5">{data.bullet_two}</li>
            </div>
          </div>
          <p className="font-futura text-base">{data.para_three}</p>
          <div className="py-5 flex justify-center items-center sm:hidden">
            <Image
              src={data.logo_url}
              className="max-h-[150px] max-w-[150px]"
            />
          </div>
        </div>
        <div className="pt-10 lg:pt-0 w-full h-auto lg:w-1/3">
          <div className="flex lg:hidden">
            <Image src={data.mobile_image_url} className="w-full h-full max-w-[600px]" />
          </div>
          <div className="hidden lg:flex lg:pl-2 lg:bg-marron h-full">
            <Image src={data.image_url} className="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRequirment;
