import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { GoPlusCircle, GoNoEntry } from "react-icons/go";

const AgentPageLayout = () => {
  useEffect(() => {
    document.title = 'Agents | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[20vw] py-10 flex flex-col gap-5 h-[60vh]">
      <h1 className="font-poppins font-bold text-xl lg:text-3xl text-marron text-center">Our Agents</h1>
      <div className="">
        {/* table section start */}
        <div className="pb-8">
          {/* for mobile */}
          <div className="block md:hidden px-[5vw]">
            <Accordion
              open={open === 1}
              icon={open !== 1 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="font-poppins text-base text-marron"
              >
                Agent
              </AccordionHeader>
              <AccordionBody className="font-futura">
                Kangaroo Global
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 2}
              icon={open !== 2 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className="font-poppins text-base text-marron"
              >
                Address
              </AccordionHeader>
              <AccordionBody className="font-futura">
                www.kangarooglobal.com.au
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 3}
              icon={open !== 3 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className="font-poppins text-base text-marron"
              >
                Contact
              </AccordionHeader>
              <AccordionBody className="font-futura">
                +61 450 213 383
              </AccordionBody>
            </Accordion>
          </div>
          {/* for large screen */}
          <div className="hidden md:block">
            <div className="shadow-custom p-2 sm:text-sm overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-marron text-white">
                    <th className="px-4 py-3">Agent</th>
                    <th className="px-4 py-3 sm:truncate">Address</th>
                    <th className="px-4 py-3 sm:truncate">Contact</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr className="bg-ash">
                    <td className="px-4 py-2.5 font-futura">Kangaroo Global</td>
                    <td className="py-3 font-futura">
                      www.kangarooglobal.com.au
                    </td>
                    <td className="px-4 py-2.5 text-center font-futura">
                      +61 450 213 383
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table section end */}
      </div>
    </div>
  );
};

export default AgentPageLayout;
