
export const Image = ({src, height, width, className}) =>{
    try {
        const urlText = decodeURIComponent(src).replace(/[^\w\s]/g, '');
        const wordToFind = "assets";
        const startIndex = src.indexOf(wordToFind);
        let image = "";
        if (startIndex !== -1) {
          image = require(`../${src.substring(startIndex)}`);
        }
        return <img className={className} src={urlText.includes(wordToFind) ? image : src} alt="get_image.jpg" height={height} width={width}/>;
      } catch (error) {
        console.log(`Image with name "${src}" does not exist`);
        return null;
      }
}