import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "./apiUrl";

 export const usePostApi = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const makePostRequest = async (url, requestBody) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${API_BASE_URL}${url}`,
        JSON.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(data)
    if (data !== null) {
      if (data.success) {
        toast.success(data.message, {
          position: "bottom-right",
          autoClose: 3000,
          className: "w-[300px] right-0",
        });
      } else {
        toast.error(data.error, {
          position: "bottom-right",
          autoClose: 3000,
          className: "w-[300px] right-0",
        });
      }
    }
  }, [data]);

  return { data, error, isLoading, makePostRequest };
};

