import React from "react";
import LandingSectionComponent from "../../../components/LandingSectionComponent";
import { landing_section } from "../../../data/homeData";
import { Button } from "../../../components/Button";
import { NavLink } from "react-router-dom";

const LandingSection = () => {
  return (
    <div>
      <LandingSectionComponent imageUrl={landing_section.image_url}>
        <div className="text-center flex flex-col gap-5 items-center">
          <p className="text-xl lg:text-2xl xl:text-3xl font-poppins font-extralight">
            {landing_section.title}
          </p>
          <p className="text-3xl xl:text-5xl font-poppins xl:w-[40vw]">
            {landing_section.description}
          </p>
          <div className="flex gap-5 pt-3">
            <NavLink to="/courses/online-application">
              <Button>Apply Here</Button>
            </NavLink>
            <NavLink to="/about-us">
            <Button transparent={true}>Learn More</Button>
            </NavLink>
          </div>
        </div>
      </LandingSectionComponent>
    </div>
  );
};

export default LandingSection;
