import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import { ButtonSubmit } from "../../../components/Button";
import { contactSectionValidationSchema } from "../../../utils/formikFormValidation";
import { usePostApi } from "../../../api/usePostApiHook";
import { CONTACT_DETAILS_API } from "../../../api/apiUrl";

const reeves_map_url =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.89698210021!2d151.08181837646765!3d-33.86654571894361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bb2f99b8355b%3A0x828c1452673cdae0!2s29%20The%20Crescent%2C%20Homebush%20NSW%202140%2C%20Australia!5e0!3m2!1sen!2sbd!4v1708178184391!5m2!1sen!2sbd";

const ContactFormSection = () => {
  //api call custom hooks
  const { isLoading, makePostRequest } = usePostApi();

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    //api call
    try {
      await makePostRequest(CONTACT_DETAILS_API, values);
      setSubmitting(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <div className="pb-10 flex flex-col lg:flex-row lg:gap-2 lg:items-center lg:justify-center">
        <div className="m-5 flex justify-center">
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              phone: "",
              email: "",
              message: "",
            }}
            validationSchema={contactSectionValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col gap-3 p-5 sm:px-16 sm:py-16 w-full md:max-w-[600px] justify-start items-start rounded shadow-custom">
                <div className="flex flex-col mb:flex-row gap-3 w-full">
                  <InputField
                    type="text"
                    name="fname"
                    placeholder="First Name*"
                  />
                  <InputField
                    type="text"
                    name="lname"
                    placeholder="Last Name*"
                  />
                </div>
                <InputField type="text" name="phone" placeholder="Phone*" />
                <InputField type="text" name="email" placeholder="Email*" />
                <TextArea
                  name="message"
                  placeholder="Type your message here..."
                />
                {/* <div className="mt-2">
                    <ReCAPTCHA
                      sitekey={clientSiteKey}
                      onChange={(value) => setFieldValue("recaptcha", value)}
                      size="normal"
                    />
                    <ErrorMessage
                      name="recaptcha"
                      component="div"
                      className="text-red-900 text-[12px]"
                    />
                  </div> */}
                <div className="flex justify-end items-end w-full">
                  <ButtonSubmit
                    type="submit"
                    name="Submit"
                    disabled={isSubmitting}
                    loader={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* map add here */}
      <div>
        <iframe
          title="Goggle Embedded Content"
          src={reeves_map_url}
          loading="lazy"
          width="600"
          height="350"
          style={{ width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
};

export default ContactFormSection;
