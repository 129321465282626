import React from "react";
import { NavLink } from "react-router-dom";
import { GoMail } from "react-icons/go";
import { GrPhone } from "react-icons/gr";
import { TfiLocationPin } from "react-icons/tfi";
import { Image } from "../../components/Image";
import { FaChevronRight } from "react-icons/fa";

const Footer = () => {
  const handleEmailOpen = () => {
    window.open("mailto:info@reevescollege.edu.au", "_self");
  };

  const handlePhoneOpen = () => {
    window.open("tel:", "_self");
  };

  return (
    <div className="bg-black text-white p-8 flex flex-col items-center justify-between">
      <div className="flex flex-col gap-5 lg:flex-row justify-between lg:px-[2vw] w-full xl:w-3/4">
        <div>
          <p className="font-bold font-poppins text-2xl text-marron">
            QUICK LINKS
          </p>
          <NavLink
            to="/"
            className="text-[17px] py-3 block w-full font-futura uppercase"
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className="text-[17px] py-3 block w-full font-futura uppercase"
          >
            About Us
          </NavLink>
          <NavLink
            to="/courses"
            className="text-[17px] py-3 block w-full font-futura uppercase"
          >
            Course
          </NavLink>
          <NavLink
            to="/contact-us"
            className="text-[17px] py-3 block w-full font-futura uppercase"
          >
            Contact
          </NavLink>
        </div>
        <div className="flex flex-col gap-3 ">
          <p className="font-bold font-poppins text-2xl text-marron">
            CONTACT INFO
          </p>
          <div className="flex items-center gap-3">
            <div className="p-1.5 bg-white w-fit rounded-full">
              <GoMail className="h-4 w-4" color="black" />
            </div>
            <p
              className="text-white cursor-pointer font-futura"
              onClick={handleEmailOpen}
            >
              info@reevescollege.edu.au
            </p>
          </div>
          <div className="flex items-center gap-3">
            <div className="p-1.5 bg-white w-fit rounded-full">
              <GrPhone className="h-4 w-4" color="black" />
            </div>
            <p
              className="text-white cursor-pointer font-futura"
              onClick={handlePhoneOpen}
            >
              02&nbsp;7226&nbsp;8887
            </p>
          </div>
          <div className="flex items-center gap-3">
            <div className="p-1.5 bg-white w-fit rounded-full">
              <TfiLocationPin className="h-4 w-4" color="black" />
            </div>
            <p className="text-white cursor-pointer font-futura">
              Level&nbsp;1 | 29&nbsp;TheCrescent Homebush NSW 2140
            </p>
          </div>
        </div>
        <form className="space-y-5">
          <p className="font-bold font-poppins text-2xl text-marron">
            SUBSCRIBE NOW
          </p>
          <div className="flex items-center">
            <input type="text" placeholder="Email*" className="px-3 py-3 outline-none text-black" required/>
            <button className="tracking-widest cursor-pointer font-poppins font-semibold truncate w-fit border-2 border-white hover:transition-all hover:duration-500 hover:ease-in-out bg-marron hover:bg-black text-white px-4 py-3">
              <FaChevronRight className="h-5 w-4"/>
            </button>
          </div>
        </form>
      </div>
      <div className="py-10 flex items-center justify-center gap-5">
        <p>Powered By</p>
        <a href="https://agentmatrix.com.au" target="_blank" rel="noopener noreferrer">
        <Image src="../../assets/logos/agent-matrix-logo.png" className="w-[150px] h-[56px]"/>
        </a>
      </div>
      <div className="flex flex-col justify-center items-center lg:flex-row lg:gap-16">
        <p>&copy; 2020 Reeves College All Right Reserved.</p>
        <p>RTO Code: 41366&nbsp;&nbsp;CRICOS: 03863F</p>
      </div>
    </div>
  );
};

export default Footer;
