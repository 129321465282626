import React, { useEffect } from "react";
import LandingSection from "./section/LandingSection";
import AboutSection from "./section/AboutSection";
import WhyReevesCollegeSection from "./section/WhyReevesCollegeSection";
import OurPrincipleSection from "./section/OurPrincipleSection";
import AboutCardSection from "./section/AboutCardSection";

const AboutPageLayout = () => {
  useEffect(() => {
    document.title = "About Us | Reeves College";
    return () => {
      document.title = "Home | Reeves College";
    };
  }, []);

  return (
    <>
      <LandingSection />
      <AboutSection />
      <WhyReevesCollegeSection />
      <OurPrincipleSection />
      <AboutCardSection />
    </>
  );
};

export default AboutPageLayout;
