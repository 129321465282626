import React, { useEffect, useRef } from "react";
import image_url from "../../assets/logos/sea-beach-image.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import NewInputField from "../../components/NewInputField";
import NewTextArea from "../../components/NewTextArea";
import { allFormSectionValidationSchema } from "../../utils/formikFormValidation";
import { ButtonSubmit } from "../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { clientSiteKey } from "../../api/reCaptchaKey";
import { ALL_FORM_API } from "../../api/apiUrl";
import { usePostApi } from "../../api/usePostApiHook";

const AllFormsPageLayout = () => {
  //api call custom hooks
  const { isLoading, makePostRequest } = usePostApi();

  const recaptchaRef = useRef(null);

  useEffect(() => {
    document.title = "All Forms | Reeves College";
    return () => {
      document.title = "Home | Reeves College";
    };
  }, []);

  const bg_image_url = {
    backgroundImage: `url(${image_url})`,
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    //api call
    try {
      await makePostRequest(ALL_FORM_API, values);
      setSubmitting(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={bg_image_url}
      className="bg-cover py-20 flex justify-center px-[5vw]"
    >
      <div className="bg-white rounded shadow-custom px-8 py-6 sm:max-w-[700px] w-full">
        <div className="text-black space-y-1">
          <p className="font-poppins font-bold text-2xl">Request to Download</p>
          <p className="font-poppins text-[15px]">Submit your details</p>
        </div>
        <div className="flex flex-col w-full">
          <div className="my-5 flex justify-center">
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                email: "",
                phone: "",
                formName: "",
                message: "",
                recaptcha: "",
              }}
              validationSchema={allFormSectionValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form className="flex flex-col gap-3 w-full justify-start items-start">
                  <div className="flex flex-col mb:flex-row gap-3 w-full">
                    <NewInputField
                      type="text"
                      name="fname"
                      placeholder="First Name"
                      label="First Name*"
                    />
                    <NewInputField
                      type="text"
                      name="lname"
                      placeholder="Last Name"
                      label="Last Name"
                    />
                  </div>
                  <NewInputField
                    type="text"
                    name="email"
                    placeholder="Email"
                    label="Email*"
                  />
                  <NewInputField
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    label="Phone*"
                  />
                  <div className="flex flex-col gap-1 items-start justify-start font-poppins w-full">
                    <label className="text-black font-poppins text-[14px]">
                      Choose a form*
                    </label>
                    <Field
                      as="select"
                      name="formName"
                      className="focus:outline-marron cursor-pointer focus:bg-white text-black w-full ml-[-1.5px] px-2 py-2 text-[14px] border-[2px] border-ash rounded"
                    >
                      <option value="none">Choose an option</option>
                      <option value="Student Transfer Request Form">
                        Student Transfer Request Form
                      </option>
                      <option value="Student Request Form">
                        Student Request Form
                      </option>
                      <option value="Student Enrollment Form">
                        Student Enrollment Form
                      </option>
                      <option value="Release Letter Application Form">
                        Release Letter Application Form
                      </option>
                      <option value="Leave of Absence Form">
                        Leave of Absence Form
                      </option>
                      <option value="Refund Request Form">
                        Refund Request Form
                      </option>
                      <option value="Change of Details Form">
                        Change of Details Form
                      </option>
                      <option value="Deferment-Suspension and Cancellation Form">
                        Deferment-Suspension and Cancellation Form
                      </option>
                      <option value="Complaints and Appeals Form">
                        Complaints and Appeals Form
                      </option>
                      <option value="Course Extension Form">
                        Course Extension Form
                      </option>
                      <option value="Course Evaluation Survey">
                        Course Evaluation Survey
                      </option>
                      <option value="Course Progress Policy">
                        Course Progress Policy
                      </option>
                      <option value="Refund Policy">Refund Policy</option>
                      <option value="Deferment Suspension and Cancellation of Enrolment Policy">
                        Deferment Suspension and Cancellation of Enrolment
                        Policy
                      </option>
                      <option value="Complaints and Appeals Policy">
                        Complaints and Appeals Policy
                      </option>
                    </Field>
                    <ErrorMessage
                      name="formName"
                      component="div"
                      className="text-red-900 text-[12px]"
                    />
                  </div>
                  <NewTextArea
                    name="message"
                    label="Type your message*"
                    placeholder="Add message here"
                  />
                  <div className="flex flex-col gap-5 sm:gap-0 sm:flex-row sm:justify-between sm:items-end w-full">
                    <div className="mt-2">
                      <ReCAPTCHA
                        sitekey={clientSiteKey}
                        onChange={(value) => setFieldValue("recaptcha", value)}
                        size="normal"
                      />
                      <ErrorMessage
                        name="recaptcha"
                        component="div"
                        className="text-red-900 text-[12px]"
                      />
                    </div>
                    <ButtonSubmit
                      type="submit"
                      name="Submit"
                      disabled={isSubmitting}
                      loader={isLoading}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFormsPageLayout;
