import React from 'react'
import { parallax_section } from '../../../data/homeData';

const ParallaxSection = () => {
  const image_url = {
    backgroundImage: `url(${parallax_section.image_url})`,
  } 
  return (
    <div className="pt-0 relative">
        <div style={image_url} className='bg-fixed bg-cover h-[380px] lg:h-[500px] bg-center'></div>
        <div className="bg-cover h-full w-full bg-center bg-black opacity-50 absolute top-0 left-0 right-0 bottom-0"></div>
        <div className="px-[5vw] sm:px-[10vw] xl:px-[15vw] w-full text-white absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center gap-6">
        <div className='text-center space-y-5 w-3/4 border-4 py-[5vh] mb:py-[12vh] px-[5vw]'>
            <p className='font-poppins text-2xl lg:text-4xl'>{parallax_section.title}</p>
            <p className='font-futura text-base'>{parallax_section.description}</p>
        </div>
      </div>
    </div>
  )
}

export default ParallaxSection;