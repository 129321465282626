export const university_section = {
  title: "Southern Cross University",
  description:
    "Southern Cross University is providing vocational pathways to Reeves College. The following qualifications on scope have discipline related pathways into Southern Cross University courses:",
  sub_name: "BSB60215- Advanced Diploma of Business",
  sub_description:
    "The below qualifications in any discipline, provide a pathway into these Southern Cross University courses:",
  course_level: "An AQF Diploma qualification",
  courses: [
    "Bachelor of Arts (3007099) CRICOS Code: 016183A",
    "Bachelor of Psychological Science (3007244) CRICOS Code 071464B",
    "Bachelor of Digital Media and Communications (3007084) CRICOS Code 091527D",
  ],
  credit_granted: "8 units (96 credit points or 33% of the course)",
  table_description:
    "Click on the link to download. Open the document. Select 'Cmd+F' for Mac or 'Ctrl+F' for Windows to reveal the search window and enter the relevant criteria (course code or name or “An AQF …”).",
};

export const university_qualification_section = {
  title:
    "What are Southern Cross University Vocational Qualification Credit Linkages?",
  description:
    "Credit Linkage arrangements facilitate entry (admission) and/or credit for prior learning into a named degree for any person holding one of the approved vocational qualifications/courses, without the need for a ‘formal agreement’ to exist between our organisations. This means all students can confidently articulate into the first or second year of a degree qualification with predetermined credit outcomes, no matter how the learning is obtained: face-to-face; on-campus; or online. If the Southern Cross University qualification is CRICOS registered, these credit linkage arrangements are also applicable to international students who will study in Australia on a student visa.",
    image_url: require("../assets/university/university-qualification.png"),
};

export const university_transfer_section = {
    title:
      "What credit transfer limits apply?",
    description_one:
      "At Southern Cross University, standard credit amounts are awarded into discipline related Southern Cross University courses under Schedule 1 of the",
      description_link:
      "Advanced Standing and Recognition of Prior Learning Procedures",
      description_two:
      "(located in clause 13). Southern Cross University will provide the maximum possible credit in recognition of prior learning, via our Advanced Standing process and reserves the right to make the final decision on the appropriate level of credit.",
  };