export const course_section_data = {
  title: "BSB60215 | ADVANCED DIPLOMA OF BUSINESS",
  paragraph_one:
    "This qualification reflects the role of individuals with significant experience in a senior administrative role who are seeking to develop expertise across a wider range of business functions.",
  paragraph_two:
    "The qualification is suited to individuals who possess significant theoretical business skills and knowledge and wish to consolidate and build pathways to further educational or employment opportunities.",
  paragraph_three:
    "Job roles and titles vary across different industry sectors. Possible job titles relevant to this qualification include:",
  paragraph_bullet: ["Senior Administrator", "Senior Executive"],
  image_url: require("../assets/courses/landing-course-image.png"),
  image_logo_url: require("../assets/courses/reeves-college.png"),
};

// graduate_diploma_course
export const graduate_diploma_qualification = {
  title: "QUALIFICATION",
  description:
    "This qualification reflects the role of individuals who apply highly specialised knowledge and skills in the field of organisational learning and capability development. Individuals in these roles generate and evaluate complex ideas. They also initiate, design and execute major learning and development functions within an organisation. Typically, they would have full responsibility and accountability for the personal output and work of others.",
};

export const graduate_diploma_delivery = {
  title: "MODE OF DELIVERY",
  description: "100% face to face and 20 hours per week",
  name: "Delivery Location:",
  location: "29 The Crescent Homebush NSW 2140",
  course_duration_title: "Course Duration:",
  course_duration: "52 weeks (40 weeks delivery plus 12 weeks in term breaks).",
  image_url: require("../assets/courses/graduate-diploma-delivery.png"),
};

export const graduate_diploma_course_req = {
  title: "COURSE REQUIREMENTS",
  description:
    "There are no entry requirements stated in the Training Package rules for BSB80120 – Graduate Diploma of Management (Learning) . However, REEVES COLLEGE requires that all international students must be 18 years of age or above and provide evidence of the following published entry requirements as stated below:",
  academic_req: {
    name: "Academic Entry Requirements:",
    rule: "Year 12 or equivalent and above, and",
  },
  english_req: {
    name: "English Language Entry Requirements:",
    rule: [
      "IELTS score 5.5 minimum (no lower than 5.0 in each band); or",
      "Cambridge First Certificate of English – Grade C (162-168); or",
      "TOEFL Paper based (PBT) score of 525; or",
      "TOEFL Internet based (IBT) score 70; or",
      "TOEIC (listen /Read-620; Speak/Write-260)",
      "PTE Score 42",
    ],
  },
  logo_url: require("../assets/courses/graduate-diploma-course-req-logo-1.png"),
  para_one:
    "All international students must have a minimum of IELTS 5.5 or equivalent and above as per published English language entry requirements. Students who need to improve their language and literacy skills must undertake an or General English or Academic English course (English for Academic Purposes) to gain the required language level before commencement into BSB80120 – Graduate Diploma of Management (Learning).",
  para_two:
    "In cases where a student has provided evidence of obtaining an IELTS 5.5 or equivalent for admission but has been unable to succeed with studies in class, an English language assessment test may be administered to identify language as a possible factor impeding successful course completion. Where new evidence demonstrates difficulty as a direct result of inadequate English language macro-skill area, students will either:",
  bullet_one:
    "Undertake concurrent English language support classes focussing on the relevant macro-skill or",
  bullet_two:
    "Suspend enrolment in the course and attend an ELICOS course for a specified period to undertake Academic English studies and return to the course upon improving English language proficiency.",
  para_three:
    "Mature age students will also be considered without the minimum education requirements but with relevant work experience within chosen area of study and a demonstrated capacity to meet course requirements. Please note that the minimum English Language Level requirements apply to all students.",
  image_url: require("../assets/courses/graduate-diploma-course-req.png"),
  mobile_image_url: require("../assets/courses/graduate-diploma-course-req-mobile.png"),
};

export const graduate_diploma_assignment = {
  title: "ASSESSMENT MARKING SYSTEM",
  bullet_one:
    "Competent (C) result: the student’s submitted work satisfies the learning requirements and all competency standards for the unit (in accordance with the National Register www.training.gov.au).",
  bullet_two:
    "Not Yet Competent (NYC) result: the student’s submitted work does not demonstrate their understanding of all competency standards in the unit. If a student receives a ‘Not Yet Competent’ outcome, he or she will receive written feedback from a trainer/assessor, clearly outlining where the gaps are. The student will then be required to rectify these gaps and re-submit their assessment for marking.",
  para_one:
    "Once a student receives a satisfactory result for all required assessment tasks a competent (C) outcome will be awarded for the entire unit. The student must demonstrate their understanding of all competency standards in each unit before receiving a competent outcome.",
  para_two:
    "Students have three (3) attempts to demonstrate competency, otherwise a unit re-enrolment fee applies",
  name: "Learner Resources",
  para_three:
    "Reeves College will provide its students with access to a wide range of learning resources. Some of those resources have been developed in-house whereas some other resources have been purchased from reputable resource providers available in the market. The resources available to students are in the form of Power point slides on each unit, Learner’s Guide or Student Workbook, access to ‘Handout’ folders in the Student online portal, which will contain handouts under each of the unit’s assessment resources.  All students will be given the opportunity to obtain the soft copies of available resources, should they desire.",
  para_four:
    "There are general use computers available at the campus. However, for ease of access, the School encourages students to bring their own laptop / media pads to the school for added convenience.",
};

export const graduate_diploma_units = {
  title:
    "THE FOLLOWING UNITS OF COMPETENCY ARE REQUIRED FOR THE COMPLETION OF THIS COURSE.",
  core_unit: {
    name: "Core units",
    para: [
      "BSBHRM613 Contribute to the development of learning and development strategies",
      "BSBLDR811 Lead strategic transformation",
      "TAELED803 Implement improved learning practice",
    ],
  },
  elective_unit: {
    name: "Elective units",
    para: [
      "BSBCRT611 Apply critical thinking for complex problem solving",
      "BSBLDR601 Lead and manage organisational change",
      "BSBLDR812 Develop and cultivate collaborative partnerships and relationships",
      "BSBOPS601 Develop and implement business plans",
      "BSBSTR801 Lead innovative thinking and practice",
    ],
  },
};

export const graduate_diploma_course_fee = {
  title: "Course Fee",
  enroll_fee: {
    name: "Enrolment Fee (non-refundable):",
    dollar: "$250",
  },
  tuition_fee: {
    name: "Tuition Fee:",
    dollar: "$12,000 (Promotions may apply)",
  },
};

// advanced_diploma_course
export const advanced_diploma_qualification = {
  title: "QUALIFICATION",
  description:
    "This qualification is designed to flexibly reflect senior management and service job roles with team and business result responsibilities in banking or other deposit-taking institutions, customer contact centers, or retail financial services environments. At this level, individuals are expected to apply specialist skills and knowledge in a range of situations to deal with complex situations and issues. They work across a broad range of technical or management functions with accountability for personal outputs and for team outcomes.",
};

export const advanced_diploma_delivery = {
  title: "MODE OF DELIVERY",
  description: "100% face to face and 20 hours per week",
  name: "Delivery Location:",
  location: "14 Railway Parade, Burwood-2134",
  course_duration_title: "Course Duration:",
  course_duration: "52 weeks (40 weeks delivery plus 12 weeks in term breaks).",
  image_url: require("../assets/courses/graduate-diploma-delivery.png"),
};

export const advanced_diploma_course_req = {
  title: "COURSE REQUIREMENTS",
  description:
    "There are no entry requirements stated in the Training Package rules for FNS60620 - Advanced Diploma of Banking Services Management (Release 1). However, REEVES COLLEGE requires that all international students must be 18 years of age or above and provide evidence of the following published entry requirements as stated below:",
  academic_req: {
    name: "Academic Entry Requirements:",
    rule: "Year 12 or equivalent and above, and",
  },
  english_req: {
    name: "English Language Entry Requirements:",
    rule: [
      "IELTS score 5.5 minimum (no lower than 5.0 in each band); or",
      "Cambridge First Certificate of English – Grade C (162-168); or",
      "TOEFL Paper based (PBT) score of 525; or",
      "TOEFL Internet based (IBT) score 70; or",
      "TOEIC (listen /Read-620; Speak/Write-260)",
      "PTE Score 42",
    ],
  },
  logo_url: require("../assets/courses/graduate-diploma-course-req-logo-1.png"),
  para_one:
    "All international students must have a minimum of IELTS 5.5 or equivalent and above as per published English language entry requirements. Students who need to improve their language and literacy skills must undertake an or General English or Academic English course (English for Academic Purposes) to gain the required language level before commencement into FNS60620 - Advanced Diploma of Banking Services Management.",
  para_two:
    "In cases where a student has provided evidence of obtaining an IELTS 5.5 or equivalent for admission but has been unable to succeed with studies in class, an English language assessment test may be administered to identify language as a possible factor impeding successful course completion. Where new evidence demonstrates difficulty as a direct result of inadequate English language macro-skill area, students will either:",
  bullet_one:
    "Undertake concurrent English language support classes focussing on the relevant macro-skill or",
  bullet_two:
    "Suspend enrolment in the course and attend an ELICOS course for a specified period to undertake Academic English studies and return to the course upon improving English language proficiency.",
  para_three:
    "Mature age students will also be considered without the minimum education requirements but with relevant work experience within chosen area of study and a demonstrated capacity to meet course requirements. Please note that the minimum English Language Level requirements apply to all students.",
  image_url: require("../assets/courses/graduate-diploma-course-req.png"),
  mobile_image_url: require("../assets/courses/graduate-diploma-course-req-mobile.png"),
};

export const advanced_diploma_assignment = {
  title: "ASSESSMENT MARKING SYSTEM",
  bullet_one:
    "Competent (C) result: the student’s submitted work satisfies the learning requirements and all competency standards for the unit (in accordance with the National Register www.training.gov.au).",
  bullet_two:
    "Not Yet Competent (NYC) result: the student’s submitted work does not demonstrate their understanding of all competency standards in the unit. If a student receives a ‘Not Yet Competent’ outcome, he or she will receive written feedback from a trainer/assessor, clearly outlining where the gaps are. The student will then be required to rectify these gaps and re-submit their assessment for marking.",
  para_one:
    "Once a student receives a satisfactory result for all required assessment tasks a competent (C) outcome will be awarded for the entire unit. The student must demonstrate their understanding of all competency standards in each unit before receiving a competent outcome.",
  para_two:
    "Students have three (3) attempts to demonstrate competency, otherwise a unit re-enrolment fee applies",
  name: "Learner Resources",
  para_three:
    "Reeves College will provide its students with access to a wide range of learning resources. Some of those resources have been developed in-house whereas some other resources have been purchased from reputable resource providers available in the market. The resources available to students are in the form of Power point slides on each unit, Learner’s Guide or Student Workbook, access to ‘Handout’ folders in the Student online portal, which will contain handouts under each of the unit’s assessment resources.  All students will be given the opportunity to obtain the soft copies of available resources, should they desire.",
  para_four:
    "There are general use computers available at the campus. However, for ease of access, the School encourages students to bring their own laptop / media pads to the school for added convenience.",
};

export const advanced_diploma_units = {
  title:
    "THE FOLLOWING UNITS OF COMPETENCY ARE REQUIRED FOR THE COMPLETION OF THIS COURSE.",
  require_skills_one: [
    {
      id: 1,
      name: "BSBCUS501 Manage quality customer service",
      description:
        "This unit describes the skills and knowledge required to develop strategies to manage organizational systems that ensure products and services are delivered and maintained to standards agreed by the organization.",
    },
    {
      id: 2,
      name: "BSBMGT502 Manage people performance",
      description:
        "This unit describes the skills and knowledge required to manage the performance of staff who report to them directly. Development of key result areas and key performance indicators and standards, coupled with regular and timely coaching and feedback, provide the basis for performance management.",
    },
    {
      id: 3,
      name: "BSBMGT605 Provide leadership across the organization",
      description:
        "This unit describes the skills and knowledge required to demonstrate senior leadership behavior and personal and professional competence. Business ethics are also addressed in this unit.",
    },
    {
      id: 4,
      name: "BSBMGT608 Manage innovation and continuous improvement",
      description:
        "This unit describes the skills and knowledge required to sustain and develop an environment in which continuous improvement, innovation and learning are promoted and rewarded.",
    },
    {
      id: 5,
      name: "BSBSMB409 Build and maintain relationships with small business stakeholders",
      description:
        "This unit describes the skills and knowledge required to negotiate with important stakeholders and modify interactions as required.",
    },
  ],
  para: "It applies to individuals who operate a small business and use communication skills to build relationships with key stakeholders.",
  require_skills_two: [
    {
      id: 1,
      name: "FNSINC411 Conduct work according to professional practices in the financial services industry",
      description:
        "This unit describes the skills and knowledge required to identify industry professional approaches to procedures, guidelines, policies, and standards, including ethical requirements, and to model and meet expectations of these in all aspects of work.",
    },
    {
      id: 2,
      name: "FNSSAM601 Monitor performance in sales of financial products or services",
      description:
        "This unit describes the skills and knowledge required to coordinate and monitor performance against organizational sales targets and quality client service standards.",
    },
    {
      id: 3,
      name: "FNSSAM602 Identify and evaluate marketing opportunities in the financial services industry",
      description:
        "This unit describes the skills and knowledge required to evaluate market data, define new and emerging market trends, and assess the viability of new product development to take advantage of marketing opportunities.",
    },
  ],
};

export const advanced_diploma_course_fee = {
  title: "Course Fee",
  enroll_fee: {
    name: "Enrolment Fee (non-refundable):",
    dollar: "$250",
  },
  tuition_fee: {
    name: "Tuition Fee:",
    dollar: "$12,000 (Promotions may apply)",
  },
};
