import React, { useState, useEffect, useRef } from "react";
import LandingSectionComponent from "../../../components/LandingSectionComponent";
import landing_image_url from "../../../assets/courses/apply-online-landing.png";
import { all_countries } from "../../../utils/allCountryData";
import FileUploader from "../../../components/FileUploader";
import { ONLINE_APPLICATION_API } from "../../../api/apiUrl";
import { ButtonSubmit } from "../../../components/Button";
import { toast } from "react-toastify";
import { fileValidation, validateForm } from "../../../utils/validationForm";

const ApplyOnlinePageLayout = () => {
  useEffect(() => {
    document.title = "Apply Online | Reeves College";
    return () => {
      document.title = "Home | Reeves College";
    };
  }, []);

  //code start here
  const formRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({ fname: "", email: "", file: "" });
  const [isFirstRender, setIsFirstRender] = useState(false);

  //this is for form validation
  useEffect(() => {
    if (isFirstRender) {
      //Perform form validation on the updated form data
      if (files.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          file: "Please, provide your documents.",
        }));
      } else {
        const fileError = fileValidation(files);
        setErrors((prevErrors) => ({ ...prevErrors, file: fileError }));
      }
    } else {
      setIsFirstRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      if (value.length > 0) {
        // Email validation
        if (!/\S+@\S+\.\S+/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Invalid email address!",
          }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Email is required*",
        }));
      }
    }

    // fname validation
    if (name === "fname") {
      if (value.length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "First name is required*",
        }));
      }
    }
  };

  //this is for form validation

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);

    const fnameValue = formData.get("fname");
    const emailValue = formData.get("email");

    // Perform form validation before submitting
    const newErrors = validateForm(
      { fname: fnameValue, email: emailValue },
      files
    );
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      //append files in form data
      files.forEach((file) => {
        formData.append("files[]", file);
      });

      //api call
      try {
        setIsLoading(true);
        const response = await fetch(ONLINE_APPLICATION_API, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (data !== null) {
          if (data.success) {
            toast.success(data.message, {
              position: "bottom-right",
              autoClose: 3000,
              className: "w-[300px] right-0",
            });
          } else {
            toast.error(data.error, {
              position: "bottom-right",
              autoClose: 3000,
              className: "w-[300px] right-0",
            });
          }
        }
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("Form has errors:", errors);
    }
  };

  return (
    <div>
      <LandingSectionComponent
        imageUrl={landing_image_url}
        height="h-[35vh] md:h-[50vh]"
      >
        <p className="text-2xl md:text-4xl xl:w-2/3 text-white font-bold font-poppins text-center uppercase">
          {"Apply Online"}
        </p>
      </LandingSectionComponent>
      {/* online application form */}
      <div className="py-10">
        <h1 className="pb-5 text-center font-poppins font-bold text-marron text-xl lg:text-3xl">
          Online Application Form
        </h1>
        <div className="flex justify-center">
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="w-full max-w-[1000px] space-y-3 px-[5vw]"
          >
            {/* Full name section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">Full Name*</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  name="fname"
                  onChange={handleChange}
                  placeholder="First name"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
                <input
                  type="text"
                  name="lname"
                  placeholder="Last name"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
            </div>
            {errors.fname && <ErrorMessage message={errors.fname} />}

            {/* Current address section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">Current Address</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  name="street_address"
                  placeholder="Street Address"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
                <input
                  type="text"
                  name="address_line"
                  placeholder="Address Line 2"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
              <div className="flex gap-3 pt-2">
                <input
                  type="text"
                  name="region"
                  placeholder="State/Province/Region"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
                <input
                  type="text"
                  name="zip_code"
                  placeholder="Postal/Zip Code"
                  className="font-poppins focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
              <div className="flex gap-3 pt-2">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
                <select
                  name="country"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                >
                  <option value="none">Select your country</option>
                  {all_countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Email section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">Email Address*</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  placeholder="Your email"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
            </div>
            {errors.email && <ErrorMessage message={errors.email} />}

            {/* File uploader section */}
            <FileUploader files={files} setFiles={setFiles} />
            {errors.file && <ErrorMessage message={errors.file} />}

            {/* Phone section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">Phone Number</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
            </div>
            {/* {touchedFields.phone && <ErrorMessage message={"dfhsdkjf"} />} */}

            {/* Apply for the course section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">Apply for the Course</label>
              <div className="flex gap-3">
                <select
                  name="course_name"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                >
                  <option value="none">Select Item</option>
                  <option value="Work 1">Work 1</option>
                  <option value="Work 2">Work 2</option>
                  <option value="Work 3">Work 3</option>
                  <option value="Any Position">Any Position</option>
                </select>
              </div>
            </div>

            {/* additional info section */}
            <div className="space-y-1">
              <label className="pl-3 font-poppins">
                Additional Information
              </label>
              <div className="flex gap-3">
                <textarea
                  name="information"
                  rows="8"
                  placeholder="Additional Information"
                  className="font-poppins focus:outline-marron focus:bg-white text-black w-full px-2.5 py-2 text-[15px] border-[1.5px] border-ash rounded"
                />
              </div>
            </div>
            {/* button section */}
            <div className="flex justify-end pt-3">
              <ButtonSubmit type="submit" name="Submit" loader={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return <div className="text-red-900 text-[13px] font-poppins">{message}</div>;
};

export default ApplyOnlinePageLayout;
