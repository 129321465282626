import React from "react";
import { university_transfer_section } from "../../../data/universityData";

const CreditTransfer = () => {
  return (
    <div className="py-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] space-y-3 md:w-3/4">
      <h1 className="font-poppins font-bold text-xl lg:text-2xl">{university_transfer_section.title}</h1>
      <div>
        <span className="font-futura">{university_transfer_section.description_one}</span>
        <a
          href="https://policies.scu.edu.au/document/view-current.php?id=204"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-[15px] underline cursor-pointer hover:text-blue-900 font-futura"
        >
          &nbsp;{university_transfer_section.description_link}&nbsp;
        </a>
        <span className="font-futura">{university_transfer_section.description_two}</span>
      </div>
    </div>
  );
};

export default CreditTransfer;
