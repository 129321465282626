import React from "react";

const CourseFee = ({ data }) => {
  return (
    <div>
      <h1 className="py-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] 2xl:px-[22vw] font-poppins font-bold text-white bg-marron text-xl">
        {data.title}
      </h1>
      <div className="pb-10 px-[5vw] lg:px-[10vw] xl:px-[15vw] 2xl:px-[22vw] space-y-2 pt-5">
        <div className="flex items-center justify-start">
          <h1 className="font-futura font-bold">{data.enroll_fee.name}</h1>
          &nbsp;&nbsp;
          <p className="font-futura">{data.enroll_fee.dollar}</p>
        </div>
        <div className="flex items-center justify-start">
          <h1 className="font-futura font-bold">{data.tuition_fee.name}</h1>
          &nbsp;&nbsp;
          <p className="font-futura">{data.tuition_fee.dollar}</p>
        </div>
      </div>
    </div>
  );
};

export default CourseFee;
