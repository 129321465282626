import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const NavBarSubMenu = ({
  name,
  route,
  subMenu = false,
  border = false,
  subMenuData,
}) => {
  const [isVisible, setVisible] = useState({ open: false, location: "" });

  const toggleSubMenuEnter = (getLink) => {
    setVisible({ ...isVisible, open: true, location: getLink });
  };
  const toggleSubMenuLeave = () => {
    setVisible({ ...isVisible, open: false, location: "" });
  };
  const activeLink = ({ isActive }) => {
    return {
      color: isActive ? "rgb(170, 34, 51)" : "black",
    };
  };
  return (
    <>
      {subMenu ? (
        <div
          onMouseEnter={() => toggleSubMenuEnter(route)}
          onMouseLeave={toggleSubMenuLeave}
          className="text-[10px] lg:text-[14px] xl:text-[16px]"
        >
          <div
            className={`${border ? "border-r border-r-marron" : "border-none"}`}
          >
            <NavLink
              to={route}
              className="px-6 py-5 flex justify-evenly items-center font-bold font-poppins"
              style={activeLink}
            >
              <span className="hover:text-marron flex justify-evenly items-center">
                {name}&nbsp;&nbsp;
                <IoIosArrowDown size={12} className="hover:text-marron" />
              </span>
            </NavLink>
          </div>

          {/* sub-page modal for sub Menu */}
          {isVisible.open && isVisible.location === route && (
            <div className="absolute z-50 flex flex-col p-2 bg-gray text-[10px] lg:text-[14px] xl:text-[16px]">
              {subMenuData.map((item, index) => (
                <NavLink
                  to={item.sub_route}
                  className="px-4 pb-3 bg-white font-poppins"
                  key={index}
                  style={activeLink}
                >
                  <span className="hover:text-marron">{item.name}</span>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`truncate ${
            border ? "border-r border-r-marron" : ""
          } hover:rounded`}
        >
          <NavLink
            to={route}
            className="px-6 py-5 flex justify-evenly items-center text-[10px] lg:text-[14px] xl:text-[16px] hover:text-marron font-bold font-poppins"
            style={activeLink}
          >
            <span className="hover:text-marron">{name}</span>
          </NavLink>
        </div>
      )}
    </>
  );
};

export default NavBarSubMenu;
