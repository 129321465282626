// export const API_BASE_URL = "http://127.0.0.1:8000";

export const API_BASE_URL = "https://api.reevescollege.edu.au"; 

export const CONTACT_DETAILS_API = "/api/send-contact-mail";

export const ALL_FORM_API = "/api/send-all-forms-mail";

// export const ONLINE_APPLICATION_API = "http://127.0.0.1:8000/api/send-application-mail";

export const ONLINE_APPLICATION_API = "https://api.reevescollege.edu.au/api/send-application-mail";
