export const landing_section = {
  title: "Create the Opportunity ",
  description: "Take the first step to start your career with us",
  image_url: require("../assets/home/landing-home.png"),
};

export const about_section = {
  description: 'Reeves College is a vocational educational institute aiming to provide an extraordinary and progressive quality education for our students. Located at Burwood, Sydney, we came into being as a passionate endeavour of a group of professionals and educational entrepreneurs collectively with long time experience. As we all know that the world is moving faster than ever with the advancement of technology, it has its effect in the job market too. Just as the jobs are variegated and demanding multi-skill from fresh graduates, it is paramount for students to equip themselves with the growing needs of time. We design, alter, recreate, update our courses keeping this in mind. Our emphasis is quality with a unique blend of conceptual and practical learning skills.',
  image_url: require('../assets/home/about-section.png'),
};

export const parallax_section = {
  title: 'Reeves College',
  description: 'Whether you’re fresh-faced and ready to take on the world, looking for a change of career, or seeking new skills and higher qualifications, Reeves College has a course for you.',
  image_url: require('../assets/home/parallax-image.png'),
};

export const qualification_section = {
  description: 'This qualification reflects the role of individuals with significant experience in a senior administrative role who are seeking to develop expertise across a wider range of business functions. The qualification is suited to individuals who possess significant theoretical business skills and knowledge and wish to consolidate and build pathways to further educational or employment opportunities.',
  image_url: require('../assets/home/qualification-image.png'),
};