import ScaleLoader from "react-spinners/ScaleLoader";

export const Button = ({
  className,
  children,
  onClick,
  disabled,
  type = "submit",
  transparent = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`tracking-widest cursor-pointer font-poppins font-semibold truncate w-fit outline outline-2 outline-white hover:transition-all hover:duration-500 hover:ease-in-out ${
        transparent ? "bg-transparent" : "bg-marron hover:bg-black"
      } text-white px-7 py-1.5 ${className}`}
    >
      {children}
    </button>
  );
};

export const ButtonSubmit = (props) => {
  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className={`tracking-widest cursor-pointer bg-marron hover:bg-black text-white px-7 py-1.5 font-poppins font-semibold truncate w-fit outline outline-2 outline-white hover:transition-all hover:duration-500 hover:ease-in-out ${props.className}`}
      onClick={props.onClick}
    >
      {props.loader ? (
        <div className="flex items-center justify-center w-full gap-2">
          <ScaleLoader color="#ffffff" height={16} />
          <span>Processing...</span>
        </div>
      ) : (
        `${props.name}`
      )}
    </button>
  );
};
