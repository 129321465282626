import React from 'react'
import { university_qualification_section } from '../../../data/universityData'
import { Image } from "../../../components/Image"

const UniversityQualification = () => {
  return (
    <div className="flex flex-col justify-center w-full lg:p-8">
      <div className="flex flex-col lg:gap-2 lg:flex-row-reverse">
        <div className="py-5 lg:w-1/2 px-[5vw] bg-marron flex flex-col gap-4 justify-start items-start lg:justify-center lg:items-center">
          <p className="font-poppins font-bold text-xl lg:text-2xl text-white">
            {university_qualification_section.title}
          </p>
          <p className="font-futura text-white">
            {university_qualification_section.description}
          </p>
        </div>
        <div className="relative bg-white py-6 lg:py-0 lg:w-1/2">
          <Image
            src={university_qualification_section.image_url}
            className="w-full max-h-[500px] h-full"
          />
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
      </div>
    </div>
  )
}

export default UniversityQualification