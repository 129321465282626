import React from "react";
import { university_section } from "../../../data/universityData";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { GoPlusCircle, GoNoEntry } from "react-icons/go";

const UniversityAbout = () => {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div className="px-[5vw] sm:px-[10vw] xl:px-[20vw] py-10 flex flex-col gap-5">
      <div className="space-y-2">
        <h1 className="text-2xl xl:text-3xl text-marron font-bold font-poppins">
          {university_section.title}
        </h1>
        <p className="font-futura">{university_section.description}</p>
      </div>
      <div className="space-y-2">
        <a
          href="https://training.gov.au/Training/Details/BSB60215"
          target="_blank"
          rel="noreferrer"
          className="text-base xl:text-xl text-black font-bold font-poppins underline cursor-pointer"
        >
          {university_section.sub_name}
        </a>
        <p className="font-futura">{university_section.sub_description}</p>
      </div>
      <div className="">
        {/* table section start */}
        <div className="pb-8">
          {/* for mobile */}
          <div className="block md:hidden px-[5vw]">
            <Accordion
              open={open === 1}
              icon={open !== 1 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="font-poppins text-base text-marron"
              >
                Course Level
              </AccordionHeader>
              <AccordionBody className="font-futura">
                {university_section.course_level}
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 2}
              icon={open !== 2 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className="font-poppins text-base text-marron"
              >
                Southern Cross University Course
              </AccordionHeader>
              <AccordionBody className="font-futura">
                <p>
                  <span className="font-bold">1.</span>&nbsp;
                  {university_section.courses[0]}
                </p>
                <p>
                  <span className="font-bold">2.</span>&nbsp;
                  {university_section.courses[1]}
                </p>
                <p>
                  <span className="font-bold">3.</span>&nbsp;
                  {university_section.courses[2]}
                </p>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={open === 3}
              icon={open !== 3 ? <GoPlusCircle /> : <GoNoEntry />}
            >
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className="font-poppins text-base text-marron"
              >
                Credit Granted
              </AccordionHeader>
              <AccordionBody className="font-futura">
                {university_section.credit_granted}
              </AccordionBody>
            </Accordion>
          </div>
          {/* for large screen */}
          <div className="hidden md:block">
            <div className="shadow-custom p-2 sm:text-sm overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-marron text-white">
                    <th className="px-4 py-3">Course Level</th>
                    <th className="px-4 py-3 sm:truncate">
                      Southern Cross University Course
                    </th>
                    <th className="px-4 py-3 sm:truncate">Credit Granted</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr className="bg-ash">
                    <td className="px-4 py-2.5 font-futura">
                      {university_section.course_level}
                    </td>
                    <td className="py-3 font-futura">
                      {university_section.courses[0]}
                    </td>
                    <td className="px-4 py-2.5 text-center font-futura">
                      {university_section.credit_granted}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="px-4 py-2.5"></td>
                    <td className="py-3 font-futura">
                      {university_section.courses[1]}
                    </td>
                    <td className="px-4 py-2.5 text-center"></td>
                  </tr>
                  <tr className="bg-ash">
                    <td className="px-4 py-2.5"></td>
                    <td className="py-3 font-futura">
                      {university_section.courses[2]}
                    </td>
                    <td className="px-4 py-2.5 text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table section end */}
        <p className="font-futura">
          View the live and searchable AQF Vocational Qualification Credit
          Linkages document published at&nbsp;
          <a
            href="https://training.gov.au/Training/Details/BSB60215"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline cursor-pointer hover:text-blue-900"
          >
            www.scu.edu.au/aqfvocationallinkages
          </a>
          &nbsp;for specific credit details into a range of undergraduate and
          postgraduate degrees.
        </p>
        <p className="font-futura">{university_section.table_description}</p>
      </div>
    </div>
  );
};

export default UniversityAbout;
