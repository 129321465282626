import React, { useEffect } from 'react'
import LandingSection from './section/LandingSection';
import UniversityAbout from './section/UniversityAbout';
import UniversityQualification from './section/UniversityQualification';
import CreditTransfer from './section/CreditTransfer';

const UniversityPathwayLayout = () => {
  useEffect(() => {
    document.title = 'University Pathway | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);

  return (
    <div>
        <LandingSection/>
        <UniversityAbout/>
        <UniversityQualification/>
        <CreditTransfer/>
    </div>
  )
}

export default UniversityPathwayLayout;