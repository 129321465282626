import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import HomePageLayout from "./pages/HomePage/HomePageLayout";
import { NavBarProvider } from "./pages/NavigationBar/ContextAPI/NavBarContext";
import AboutPageLayout from "./pages/AboutPage/AboutPageLayout";
import UniversityPathwayLayout from "./pages/UniversityPathwayPage/UniversityPathwayLayout";
import AgentPageLayout from "./pages/AgentsPage/AgentPageLayout";
import ContactPageLayout from "./pages/ContactPage/ContactPageLayout";
import AllFormsPageLayout from "./pages/AllFormsPage/AllFormsPageLayout";
import CoursesPageLayout from "./pages/CoursesPage/CoursesPageLayout";
import GraduateDiplomaPageLayout from "./pages/CoursesPage/GraduateDiplomaPage/GraduateDiplomaPageLayout";
import AdvancedDiplomaPageLayout from "./pages/CoursesPage/AdvancedDiplomaPage/AdvancedDiplomaPageLayout";
import ApplyOnlinePageLayout from "./pages/CoursesPage/ApplyOnlinePage/ApplyOnlinePageLayout";

const App = () => {
  return (
    <NavBarProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePageLayout />} />
            <Route path="/about-us" element={<AboutPageLayout />} />
            <Route
              path="/university-pathway"
              element={<UniversityPathwayLayout />}
            />
            <Route path="/agents" element={<AgentPageLayout />} />
            <Route path="/contact-us" element={<ContactPageLayout />} />
            <Route path="/all-forms" element={<AllFormsPageLayout />} />
            {/* Course routes start */}
            <Route path="/courses" element={<CoursesPageLayout />} />
            <Route
              path="/courses/graduate-diploma-of-management"
              element={<GraduateDiplomaPageLayout />}
            />
            <Route
              path="/courses/advanced-diploma-of-banking"
              element={<AdvancedDiplomaPageLayout />}
            />
            <Route
              path="/courses/online-application"
              element={<ApplyOnlinePageLayout />}
            />
            {/* Course routes end */}
          </Route>
        </Routes>
      </Router>
    </NavBarProvider>
  );
};

export default App;
