export const validateForm = (data, files) => {
  const errors = {};

  //First name validation
  if(data.fname.length === 0){
    errors.fname = "First name is required*";
  } 


  // Email validation
  if (!data.email.trim()) {
    errors.email = "Email is required*";
  } 

  //file validation
  if (files.length === 0) {
    errors.file = "Please, provide your documents.";
  }

  return errors;
};

export const fileValidation = (files) => {
  let errorFile = "";
  // Perform file validation
  const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  const maxSize = 5 * 1024 * 1024; // 5 MB

  const invalidFiles = files.filter(file => (
    !allowedTypes.includes(file.type) || file.size > maxSize
  ));

  if (invalidFiles.length > 0) {
    errorFile = 'Invalid file types or sizes: JPEG, PNG, PDF files up to 5MB are allowed.';
  } else {
    errorFile = "";
  }

  return errorFile;
};
