import React, { useEffect } from 'react'
import LandingSection from './section/LandingSection';
import AboutSection from './section/AboutSection';
import ParallaxSection from './section/ParallaxSection';
import QualificationSection from './section/QualificationSection';
import ContactSection from './section/ContactSection';

const HomePageLayout = () => {
  useEffect(() => {
    document.title = 'Home | Reeves College';
    return () => {
      document.title = 'Home | Reeves College';
    };
  }, []);

  return (
    <>
      <LandingSection/>
      <AboutSection/>
      <ParallaxSection/>
      <QualificationSection/>
      <ContactSection/>
    </>
  )
}

export default HomePageLayout;