import React from "react";
import { useField } from "formik";

const InputField = (props) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      <div className="">
        <input
          {...field}
          {...props}
          className={`focus:outline-marron focus:bg-white w-full px-2.5 py-3 border-[2px] border-ash`}
        />
        
      </div>
      {meta.error && meta.touched && (
        <div className="text-[12px] text-red-900 mt-1">
          {meta.error}
          <sup>*</sup>
        </div>
      )}
    </div>
  );
};

export default InputField;
